import './SessionsTable.css';
import React from 'react';
import Session from './Session';

// props: data = API call from Zoom
function SessionsTable(props) {

    return (
        <div>
            <h3><em>Sessions</em></h3>
            <table style={{ minWidth: "869px" }} className="table">
                <tbody>
                    <tr>
                        <th>Date</th>
                        <th>Session Name</th>
                        <th>Session ID</th>
                        <th>Session Duration<br />(hh:mm)</th>
                        <th>Maximum<br />Participants</th>
                        <th>IzzyCast<br />Minutes Used</th>
                    </tr>
                    {props.data.map(session => (<Session session={session} />))}
                </tbody>
            </table>

            <br />
        </div>
    );
}
export default SessionsTable;
