import './AccountPage.css';
import Header from './Header';
import SessionsTable from './SessionsTable';
import ReservationsTable from './ReservationsTable';
import isAlphaNumeric from './helpers';
import React, { useEffect, useState } from 'react';

function AccountPage() {

    const licenseKey = localStorage.getItem("licenseKey")
    const [licenseMinutes, setLicenseMinutes] = useState(0)
    const getLicenseMinutes = () => {
        fetch(`http://3.123.28.2:8080/checkLicense?key=${licenseKey}`)
            .then(data => data.json())
            .then(json => setLicenseMinutes(json.num_minutes))
        
    }
    const [reservationName, setReservationName] = useState("")
    const [past, setPast] = useState(true);
    const [future, setFuture] = useState(true);
    const [endDate, setEndDate] = useState(new Date());
    var currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 3);
    const [startDate, setStartDate] = useState(currentDate)
    const getReservations = () => {
        fetch('http://3.123.28.2:8080/listReservations?key=' + licenseKey)
            .then(data => data.json())
            .then(json => setReservations(json))
    }
    const getSessions = () => {
        let endTime = Math.floor(endDate / 1000)
        let startTime = Math.floor(startDate / 1000)
        fetch(`http://3.123.28.2:8080/listSessions?key=${licenseKey}&start=${startTime}&end=${endTime}`)
            .then(data => data.json())
            .then(json => setSessions(json))
    }
    const [sessions, setSessions] = useState([])
    
    const generateId = () => {
        if (isAlphaNumeric(reservationName) && reservationName !== "") {
            fetch(`http://3.123.28.2:8080/createReservation?key=${licenseKey}&displayName=${reservationName}`)
                .then(data => data.json())
                .then(json => setReservations(reservations.concat(json)))
        } else {
            alert("Reserved Session Label must be alphanumeric")
        }
    };
    const [reservations, setReservations] = useState([]);
    const [filteredData, setFilteredData] = useState(sessions);
    const filterSessions = () => {
        if ((Math.floor((endDate / 1000) - 24 * 60 * 60 ) > sessions.End) || (Math.floor((startDate / 1000)) < sessions.Start)) {
            getSessions()
        }
        if (sessions.Sessions) {
            var filtered = sessions.Sessions.filter((session => ((session.StartTime < Math.floor((endDate / 1000) + 24 * 60 * 60)))))
            filtered = filtered.filter((session => ((session.StartTime > Math.floor((startDate / 1000) + 24 * 60 * 60)))))
            setFilteredData(filtered)
        }
    };

    useEffect(() => {
        getSessions()
        getReservations()
        getLicenseMinutes()
    }, [])
    useEffect(() => {
        filterSessions();
    }, [startDate, endDate, sessions])
    return (
        <div>
            <Header />
            <div id="accountPage" style={{ backgroundImage: "linear-gradient(to right, #737474 , #4d6dab)", minHeight: "1000px" }}>

                <div className="innerdiv">

                    <table style={{ minWidth: "869px" }} >
                        <tbody>
                            <tr>
                                <td className="minutetotal" colSpan="6"><strong>IzzyCast User Portal</strong></td>
                            </tr>
                        </tbody>
                    </table>

                    <h2>Account Key: {licenseKey.substring(0, 15) + "****"}</h2>
                    <h2>Current IzzyCast Minutes Available: {licenseMinutes}</h2>

                    <hr />

                    <h1>Create an Advance Session ID</h1>
                    <p>This tool allows you to generate an IzzyCast Session ID in advance, so that you can distribute it to all the participants before the session begins.</p>
                    <p>They will only be able to join an IzzyCast session after it is created using your IzzyCast Account Key and the advance Session ID you generate here. A Session ID alone does not allow them to start an IzzyCast session.</p>
                    <p>To generate a new session ID, enter a name for the session and click the "Generate Session ID" button.</p>
                    <form>
                        <label htmlFor="session_name">Session Name:</label>
                        <input style={{ fontSize: "16px" }} type="text" id="session_name" name="session_name" placeholder="Enter Session Name..." onChange={(e) => setReservationName(e.target.value)} />
                        <input id="clickMe" type="button" value="Generate Session ID" onClick={() => { generateId() }} />
                    </form>
                    <br />
                    <ReservationsTable data={reservations} />
                    <br />
                    <br />
                    <hr />

                    <h1>Usage Statistics</h1>
                    <h3><em>Filters</em></h3>
                    <table>
                        <tbody>
                            <tr>
                                <td><label style={{ color: "black" }} htmlFor="session_name_filter">Session Name:</label></td>
                                <td><input id="session_name_filter" style={{ fontSize: "14px", minWidth: "260px" }} type="text" /></td>
                            </tr>

                            <tr>
                                <td><label style={{ color: "black" }} htmlFor="session_name_filter">Session Type:</label></td>
                                <td>
                                    <input id="past_sessions" style={{ fontSize: "14px" }} name="past_sessions" type="checkbox" checked={!past} onChange={() => { setPast(current => !current) }} />
                                    <label style={{ color: "black" }} htmlFor="past_sessions">Past:</label>
                                    &nbsp;
                                    <input id="upcoming_sessions" style={{ fontSize: "14px" }} name="upcoming_sessions" type="checkbox" checked={!future} onChange={() => { setFuture(current => !current) }} />
                                    <label style={{ color: "black" }} htmlFor="upcoming_sessions">Upcoming:</label>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <label style={{ color: "black" }} htmlFor="date_range_start">Date Range:</label>
                                </td>
                                <td>
                                    <label style={{ color: "black" }} htmlFor="date_range_start">Start:</label>
                                    <input id="date_range_start" style={{ fontSize: "14px" }} type="date" defaultValue={startDate.toISOString().substring(0, 10)} onChange={(event) => { setStartDate(event.target.valueAsDate) }} />
                                    <label style={{ color: "black" }} htmlFor="date_range_end">End:</label>
                                    <input id="date_range_end" style={{ fontSize: "14px" }} type="date" defaultValue={endDate.toISOString().substring(0, 10)} onChange={(event) => { setEndDate(event.target.valueAsDate) }} />
                                </td>
                            </tr>
                        </tbody>
                    </table>


                    <br />
                    <SessionsTable data={filteredData} />
                    <br />

                    <table style={{ minWidth: "869px" }} >
                        <tbody>
                            <tr>
                                <td className="minutetotal" colSpan="6">Total IzzyCast Minutes Used: 3752</td>
                            </tr>
                        </tbody>
                    </table>

                    <br />
                </div>
            </div>
        </div>
    );
}
export default AccountPage;
