import './PortalLogin.css';
import Header from './Header';
import React, { useEffect, useState } from 'react';


function PortalLogin() {
    const [licenseKey, setLicenseKey] = useState("")
    const [rehearsalCount, setRehearsalCount] = useState(0);
    const [rehearsalDuration, setRehearsalDuration] = useState(0);
    const [rehearsalPeople, setRehearsalPeople] = useState(0);
    const [performanceCount, setPerformanceCount] = useState(0);
    const [performanceDuration, setPerformanceDuration] = useState(0);
    const [performancePeople, setPerformancePeople] = useState(0);
    const [sum, setSum] = useState(0);

    const inputLicense = () => {
        localStorage.setItem("licenseKey", licenseKey)
        window.location.href = window.location.href.substring(0, window.location.href.lastIndexOf("/")) + "/info"
    }

    useEffect(() => {
        var total = rehearsalCount * rehearsalDuration * 60 * rehearsalPeople + performanceCount * performanceDuration * 60 * performancePeople;

        if (isNaN(total)) {
            total = "???"
        }
        setSum(total)
    }, [rehearsalCount, rehearsalDuration, rehearsalPeople, performanceCount, performanceDuration, performancePeople])

    return (
        <div>
            <Header />

            <div className="innerdiv">

                <h2>IzzyCast User Portal</h2>
                <p>Please log in to see your IzzyCast Account Statisics:</p>
                <form>
                    <label htmlFor="account_key">Account Key:</label>
                    <input style={{ fontSize: "16px" }} type="text" id="account_key" name="account_key" placeholder="Enter Account Key..." onChange={(e) => setLicenseKey(e.target.value)} />
                    <input id="clickMe" type="button" value="Login" onClick={() => { inputLicense() }} />
                </form>

                <hr />

                <h2>IzzyCast Minutes Calculator</h2>

                <p>This calculator will help you to estimate how many IzzyCast Minutes you'll need for a show.</p>
                <p>Enter the number of rehearsals and/or performances, the duration of each, and the number of people that will be logged into the IzzyCast session. Then look below to see the total number of IzzyCast Minutes you will need to purchase for your production.</p>
                <table className="table">
                    <tbody>
                        <tr>
                            <table style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th style={{ fontSize: "20px", fontWeight: "bold" }} colSpan="3">Rehearsals</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Number of Rehearsals</td>
                                        <td>Rehearsal Duration (Hours)</td>
                                        <td>IzzyCast Participants</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input id="rehearsal_count" style={{ fontSize: "16px" }} type="number" value={rehearsalCount} onChange={(e) => { setRehearsalCount(e.target.value) }} />
                                        </td>
                                        <td>
                                            <input id="rehearsal_duration" style={{ fontSize: "16px" }} type="number" value={rehearsalDuration} onChange={(e) => { setRehearsalDuration(e.target.value); }} />
                                        </td>
                                        <td>
                                            <input id="rehearsal_people" style={{ fontSize: "16px" }} type="number" value={rehearsalPeople} onChange={(e) => { setRehearsalPeople(e.target.value); }} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </tr>
                        <tr>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ fontSize: "20px", fontWeight: "bold" }} colSpan="3">Performances</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Number of Performances</td>
                                        <td>Performance Duration (Hours)</td>
                                        <td>IzzyCast Participants</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input id="performance_count" style={{ fontSize: "16px" }} type="number" value={performanceCount} onChange={(e) => { setPerformanceCount(e.target.value) }} />
                                        </td>
                                        <td>
                                            <input id="performance_duration" style={{ fontSize: "16px" }} type="number" value={performanceDuration} onChange={(e) => { setPerformanceDuration(e.target.value) }} />
                                        </td>
                                        <td>
                                            <input id="performance_people" style={{ fontSize: "16px" }} type="number" value={performancePeople} onChange={(e) => { setPerformancePeople(e.target.value) }} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td id="total-izzycast-minutes" style={{ fontSize: "20px", fontWeight: "bold", padding: "20px 0px 20px 0px" }} colSpan="3">Total IzzyCast Minutes Required: {sum}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </tr>
                    </tbody>
                </table>

            </div>

        </div>
    );
}

export default PortalLogin;
