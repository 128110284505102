import './App.css';
import Header from './Header';
import { Routes, Route } from 'react-router-dom';
import PortalLogin from './PortalLogin';
import AccountPage from './AccountPage';

function App() {
  return (
    <div><Routes>
      <Route path='/' element={<Header />} />
      <Route path='/login' element={<PortalLogin />} />
      <Route path='/info' element={< AccountPage/>}/>
    </Routes></div>

  );
}

export default App;
