import './Session.css';

function Session(props) {
    var utcSeconds = props.session.StartTime;
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds);    
    return (
        <tr>
            <td>{d.toLocaleDateString("en-US") + " " + d.toLocaleTimeString("it-IT")}</td>
            <td>{props.session.SessionLabel}</td>
            <td>{props.session.SessionName}</td>
            <td>{props.session.MinutesUsed}</td>
            <td>{props.session.Participants}</td>
            <td>{props.session.MinutesUsed}</td>
        </tr>
    );
}
export default Session;
