import './Reservation.css';
import React from 'react';

function Reservation(props) {
    return (
            <tr>
                <td>{props.reservation.display_name}</td>
                <td>{props.reservation.session_id}</td>
            </tr>
    );
}
export default Reservation;
