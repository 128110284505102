import logo from './logo.svg';
import './Header.css';

function Header() {
    return (
        <div>
            <head>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width" />
                <meta property="og:title" content="TROIKATRONIX : ISADORA" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://troikatronix.com/wp-content/themes/troikatronix/m/share/default.jpg" />
                <meta property="og:image:width" content="1300" />
                <meta property="og:image:height" content="680" />
                <title>TROIKATRONIX : ISADORA</title>
                <base href="https://troikatronix.com" />

                <meta name="robots" content="max-image-preview:large" />
                <link rel="canonical" href="https://troikatronix.com/" />
                <link rel="shortlink" href="https://troikatronix.com/" />
                <link rel="icon" href="https://troikatronix.com/wp-content/uploads/2017/01/cropped-isadora-icon-v25-1024-32x32.png" sizes="32x32" />
                <link rel="icon" href="https://troikatronix.com/wp-content/uploads/2017/01/cropped-isadora-icon-v25-1024-192x192.png" sizes="192x192" />
                <link rel="apple-touch-icon" href="https://troikatronix.com/wp-content/uploads/2017/01/cropped-isadora-icon-v25-1024-180x180.png" />
                <meta name="msapplication-TileImage" content="https://troikatronix.com/wp-content/uploads/2017/01/cropped-isadora-icon-v25-1024-270x270.png" />
            </head>


            <div id="page" style={{ width: "100%", paddingBottom: "10px" }}>
                <div id="header">
                    <div className="center">
                        <div className="r">
                            <a href="https://troikatronix.com/home/" id="logo"></a>
                            <ul className="navigation"><li>
                                <a href="https://troikatronix.com/isadora-new/">Isadora</a>
                            </li><li>
                                    <a href="https://troikatronix.com/get-it/">Get It</a>
                                </li><li>
                                    <a href="https://community.troikatronix.com/">Forum</a>
                                </li><li>
                                    <a href="https://support.troikatronix.com/support/home">Help</a>
                                </li><li>
                                    <a href="https://troikatronix.com/add-ons/">ADD-ONS</a>
                                </li><li>
                                    <a href="http://localhost:3000/info" className="selected">IzzyCast</a>
                                </li><li style={{ display: "inline"}}>
                                    <a href="http://seu.cleverreach.com/f/13929-62133/">Newsletter</a>
                                </li><li>
                                    <a href="https://troikatronix.com/impressum/">Impressum</a>
                                </li><li>
                                    <a href="https://troikatronix.com/dsgvo/">Dsgvo</a></li>
                            </ul>          		<span className="marker" style={{ left: "0px", width: "88px" }}></span>
                            <a href="#" className="button">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </a>
                            <ul className="meta">
                                <li><a href="https://troikatronix.com/dsgvo/">Dsgvo</a></li>
                                <li><a href="https://troikatronix.com/impressum/">Impressum</a></li>
                                <li><a href="https://www.facebook.com/Isadora-214094621937491/" className="social facebook"></a></li>
                                <li><a href="https://twitter.com/troikatronix" className="social twitter"></a></li>
                                <li><a href="https://www.instagram.com/troikatronix_isadora/" className="social instagram"></a></li>
                                <li><a href="https://www.youtube.com/user/troikatronix" className="social youtube"></a></li>
                            </ul>
                            <div className="state">Isadora</div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default Header;
