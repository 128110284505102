import './ReservationsTable.css';
import React from 'react';
import Reservation from './Reservation';

// props: data = /listReservations from server
function ReservationsTable(props) {

    return (
        <div>
            <h3><em>Reserved Sessions</em></h3>
            <table style={{ minWidth: "869px" }} className="table">
                <tbody>
                    <tr>
                        <th>Session Label</th>
                        <th>Session ID</th>
                    </tr>
                    {props.data?.map(reservation => (<Reservation reservation={reservation} />))}
                </tbody>
            </table>

            <br />
        </div>
    );
}
export default ReservationsTable;
